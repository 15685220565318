<template>
  <div class="main bgf8">
    <div class="w1200 padc30">
      <div class="m_con p-40">
        <div><img class="img_box" :src="DoctorList.headImg" alt="" /></div>
        <div class="m-l-30 flex-1">
          <div class="font30 color333">{{ DoctorList.name }}</div>
          <div class="font16 color666 marc17">
            {{ DoctorList.label }} ｜{{ DoctorList.school }} ｜
            {{ DoctorList.rank }}
          </div>
          <div class="flex">
            <div class="course m-r-20">{{ DoctorList.count }}个课程</div>
            <div class="courses">
              <img src="../assets/images/icon_31.png" alt="" /> 已播放{{
                DoctorList.play
              }}次
            </div>
          </div>
          <Divider />
          <div class="font20 color333">专家介绍</div>
          <div
            class="font14 color666 m-t-7 introduce"
            v-html="filterChar(DoctorList.content)"
          ></div>
        </div>
      </div>
      <div class="b_course m-t-20 p-20">
        <p class="font24 color333 m-b-20">他的课程</p>
        <div>
          <div class="flex-wrap">
            <div
              class="mc-li m-r-9 m-b-20"
              v-for="(item, i) in menuNav"
              :key="i"
            >
              <CourseItem
                imgw="280"
                imgh="175"
                class="p-b-28"
                @faBtn="faBtn(item)"
                :coverImg="item.coverImg"
                :title="item.title"
                :doctoName="item.teacher"
                :introduce="item.label"
                :price="item.salePrice"
                :original="item.markPrice"
                :playNum="item.realPlayCount"
                :free="item.free == 1 ? true : false"
              ></CourseItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "../components/CourseItem.vue";
import { lecturerInfo } from "@/api/home";

export default {
  components: {
    CourseItem,
  },
  data() {
    return {
      lecturerId: "",
      DoctorList: {},
      menuNav: [],
    };
  },
  mounted() {},
  methods: {
    faBtn(item) {
      // console.log("item",item);
      this.$router.push({ path: "/CourseDetail", query: item });
    },
    filterChar(html) {
      if (html != null) {
        return html
          .replace(html ? /&(?!#?\w+;)/g : /&/g, "&amp;")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;nbsp;/g, "&nbsp;")
          .replace(/&quot;/g, '"')
          .replace(/&#39;/g, "'");
      }
    },
    getDoctor() {
      lecturerInfo({ lecturerId: this.lecturerId || this.teacherId }).then(
        (res) => {
          this.DoctorList = res.data;
        }
      );
    },
    getFeatured() {
      $.ajax({
        url: "https://yzx.yeswedo.com.cn/api/index/course_list",
        type: "get",
        data: { handpick: 0, pageNum: this.order_page },
        dataType: "json",
      })
        .then((res) => {
          this.menuNav = res.data.data;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.per_page = res.data.per_page;
          this.last_page = res.data.last_page;
        })
        .fail(function (err) {});
    },
  },
  created() {
    this.lecturerId = this.$route.query.lecturerId;
    this.teacherId = this.$route.query.teacherId;
    console.log("this.lecturerId", this.lecturerId);
    this.getDoctor();
    this.getFeatured();
  },
};
</script>

<style lang="scss" scoped>
.m_con {
  height: 500px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(204, 204, 204, 0.2);
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  .img_box {
    width: 200px;
    height: 200px;
  }
  .course {
    width: 100px;
    height: 28px;
    border-radius: 18px;
    border: 1px solid #e2e2e2;
    text-align: center;
    line-height: 28px;
  }
  .courses {
    width: 140px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e2e2e2;
    text-align: center;
    line-height: 30px;
  }
}
.b_course {
  //   height: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(204, 204, 204, 0.2);
  border-radius: 6px;
  border: 1px solid #f0f0f0;
}
.introduce {
  height: 235px;
  overflow: auto;
}
</style>